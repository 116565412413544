var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [!_vm.rows.records || _vm.rows.records.length < 1 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": _vm.colSpan,
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('span', {
      staticClass: "member"
    }, [_vm._v(_vm._s(_vm.isDependentRow ? row.primary_full_name : row.member_name))])]), _vm.isDependentRow ? _c('td', [_c('span', {
      staticClass: "dependent"
    }, [_vm._v(_vm._s(row.dependent_full_name))])]) : _vm._e(), _c('td', [_c('span', {
      staticClass: "turns"
    }, [_vm._v(_vm._s(_vm.isDependentRow ? _vm.getYearAndMonth(row.turn_age_date) : _vm.getYearAndMonth(row.turn_age_date_65)))])]), _vm.type != 'pendingMember65' && _vm.type != 'pendingDependentSpouse65' ? _c('td', [_c('span', {
      staticClass: "dob"
    }, [_vm._v(_vm._s(row.termination_date))])]) : _vm._e(), _c('td', [_c('span', {
      staticClass: "rep"
    }, [_vm._v(_vm._s(row.rep_full_name))])]), _c('td', [_c('span', {
      staticClass: "group"
    }, [_vm._v(_vm._s(row.group_name))])]), _c('td', _vm._l(row.plan_name, function (plan) {
      return _c('span', {
        key: plan,
        staticClass: "plans"
      }, [_vm._v(" " + _vm._s(plan) + " ")]);
    }), 0), _c('td', [row.benefit_store_link ? _c('div', {
      staticClass: "action-btn"
    }, [_c('custom-button', {
      attrs: {
        "title": "Enroll Now",
        "iconClass": "fas fa-shopping-cart"
      },
      on: {
        "onClicked": function onClicked($event) {
          return _vm.openUrl(row.benefit_store_link);
        }
      }
    })], 1) : _vm._e()])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }