<template>
  <tbody>
    <tr v-if="!rows.records || rows.records.length < 1">
      <td :colspan="colSpan" align="center">No records found.</td>
    </tr>
    <tr v-for="(row, index) in rows.records" :key="index">
      <td>
        <span class="member">{{
          isDependentRow ? row.primary_full_name : row.member_name
        }}</span>
      </td>
      <td v-if="isDependentRow">
        <span class="dependent">{{ row.dependent_full_name }}</span>
      </td>
      <td>
        <span class="turns">{{
          isDependentRow
            ? getYearAndMonth(row.turn_age_date)
            : getYearAndMonth(row.turn_age_date_65)
        }}</span>
      </td>
     <td v-if="type!='pendingMember65' && type!='pendingDependentSpouse65'">
        <span class="dob">{{ row.termination_date }}</span>
      </td>
      <td>
        <span class="rep">{{ row.rep_full_name }}</span>
      </td>
      <td>
        <span class="group">{{ row.group_name }}</span>
      </td>
      <td>
        <span class="plans" v-for="plan in row.plan_name" :key="plan">
          {{ plan }}
        </span>
      </td>
      <td>
         <div class="action-btn" v-if="row.benefit_store_link">
          <custom-button
            title="Enroll Now"
            iconClass="fas fa-shopping-cart"
            @onClicked="openUrl(row.benefit_store_link)"
          />
         </div>
      </td>
    </tr>
  </tbody>
</template>
<script>
import moment from "moment";
export default {
  props: {
    rows: {
      required: true,
    },
    colSpan: {
      type: Number,
      required: true,
    },
    isDependentRow: {
      type: Boolean,
      required: false,
      default: false,
    },
    type:{
      type:String,
      required:false,
      default: null
    }
  },
  methods: {
    getYearAndMonth(date) {
      return moment(date).format("YYYY MMMM");
    },
    openUrl(url){
      return window.open(url,"_blank");
    }
  },
};
</script>

<style scoped>
.table-card .action-btn a {
    background: transparent linear-gradient(180deg,#0cf,#1597b8 49%,#0567bc);
    border-radius: 50px;
    width: 27px;
    height: 27px;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 10px 8px;
}
@media (max-width: 1400px){
  .table-card .action-btn a {
      width: 24px;
      height: 24px;
  }
}
</style>